import React from 'react';
import { Navigate } from "react-router-dom";
import LayoutContent from './LayoutContent'
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const PrivateRoute = ({ children}) => {
    const userReducer = useSelector((state) => state?.userReducer);
    const isExpire = () => {
        let isPass = false;
        if (userReducer && userReducer?.data && userReducer?.data?.access_token) {
            const decodedToken = jwtDecode(userReducer?.data?.access_token);
            if (decodedToken && decodedToken?.exp) {
                if (moment(decodedToken?.exp * 1000).isAfter(moment())) {
                    isPass = true
                }
            }
        }
        return isPass;
    }
    
    return isExpire() ? <LayoutContent children={children}/> : <Navigate to="/login" />;
}


export default PrivateRoute;
import { Button, Col, Form, Input, Row, Space, theme, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { BASE_URL } from '../../settings/config';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import { getListAccount } from '../../services/apis';

const ListAccountPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const userReducer = useSelector(state => state?.userReducer);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
        },
        {
            title: 'Email gửi',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Mật khẩu',
            dataIndex: 'password',
            key: 'password',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Thời gian tạo',
            dataIndex: 'createAt',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        // {
        //     title: 'Hành động',
        //     key: 'action',
        //     render: (text, record) => <a onClick={() => handleEdit(record)} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>Sửa</a>
        // },

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getDataUsers = async (keyword, disable) => {
        
    }

    
    const onFinish = async (data) => {
        getDataUsers(data?.name);
    };

    
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataUsers('', true)])
            .catch(err => consoleLogUtil("errr", err))
            .finally(() => setLoading(false))
    }, [])

    return (
        <div className='container-content'>
            
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Nhập email'} />
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
            />
            <LoadingBase loading={loading} />
            
        </div>
    )
}
export default React.memo(ListAccountPage);
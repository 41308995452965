import { Button, Input, Modal, Form, Row, Col, Space, message, InputNumber, Upload, Image, Select } from "antd"
import React, { useEffect, useState } from "react";
import { TYPE_MODAL } from "../../../constants/constants";
import { requiredForm } from "../../../utils/requiredform";
import { PlusOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../settings/config";
import { filterOption } from "../../../utils/funcHelper";
import ItemMenu from "./ItemMenu";
import ModalAddMenu from "./ModalAddMenu";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const AddDataModal = ({ visible, onCancel, title, type, data, lstProvince, lstType, onSave }) => {
    const [form] = Form.useForm();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [typeSelected, setTypeSelected] = useState();
    const [fileList, setFileList] = useState([]);
    const [lstMenu, setLstMenu] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [menuSelected, setMenuSelected] = useState();
    const [typeModalMenu, setTypeModalMenu] = useState();
    const [indexEdit, setIndexEdit] = useState();
    const [isDisable, setIsDisable] = useState(false);


    const beforeUpload = (file) => {
        const isLt21M = file.size / 1024 / 1024 < 2;
        if (!isLt21M) {
            message.error('Ảnh phải có kích thước nhỏ hơn 2mb!');
            return false;
        }

        return true;
    };

    const props = {
        action: BASE_URL + 'upload',
    };
    const handleGetTypeService = async (keyword) => {
        setTypeSelected(keyword);
    }

    const onFinish = (dataF) => {
        if (uploading) {
            message.warning("Upload ảnh chưa hoàn tất. Vui lòng đợi");
            return;
        }
        if (type === TYPE_MODAL.ADD) {
            const temp = { ...dataF };
            if (fileList.length > 0) {
                temp['attachFiles'] = [...fileList.map(item => ({
                    file_path: item?.response?.image_path
                }))];
            } else {
                temp['attachFiles'] = [];
            }
            if (lstMenu.length > 0) {
                temp['menus'] = [...lstMenu];
            } else {
                temp['menus'] = [];
            }
            onSave(temp);
        } else if (type === TYPE_MODAL.EDIT) {
            const temp = {
                ...data,
                ...dataF,
                menus: lstMenu,
                attachFiles: [...fileList.map(item => ({
                    file_path: item?.response?.image_path
                }))]
            };
            onSave(temp);
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getFileExtension = (url) => {
        const extension = url.split('.').pop().split('?')[0];
        return extension.toLowerCase();
    };

    const handlePreview = async (file) => {
        debugger
        const extension = getFileExtension(file.url || file.preview || '');
        if (extension === 'pdf') {
            window.open(file.url, '_blank');
            return;
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleChange = (info) => {
        const { file, fileList} = info;
            if (file.status === 'done') {
                setUploading(false);
            } else if (file.status === 'error') {
                setUploading(false);
                message.error("Tải ảnh thất bại. Vui lòng thử lại")
            } else {
                setUploading(true);
            }
        setFileList(fileList);
    } 
    const handleEditMenu = (index) => {
        setTypeModalMenu(TYPE_MODAL.EDIT);
        setMenuSelected(lstMenu[index]);
        setIndexEdit(index);
        setShowModal(true);
    }
    const handleDeleteMenu = (index) => {
        const temp = [...lstMenu];
        temp.splice(index, 1);
        setLstMenu([...temp]);
    }
    const handleCopyMenu = (index) => {
        const temp = [...lstMenu];
        const newMenu = temp[index];
        if (newMenu?.id) {
            delete newMenu.id;
        }
        temp.push(newMenu);
        setLstMenu([...temp]);
    }
    const handleAddMenu = () => {
        setTypeModalMenu(TYPE_MODAL.ADD);
        setShowModal(true);
    }
    const handleOnSaveMenu = (menu, index) => {
        setShowModal(false);
        if (typeModalMenu === TYPE_MODAL.ADD) {
            const temp = [...lstMenu];
            temp.push(menu);
            setLstMenu([...temp]);
        } else {
            const temp = [...lstMenu];
            temp[index] = menu;
            setLstMenu([...temp]);
        }

    }

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const renderModal = () => {
        if (!typeSelected) {
            return;
        }
        return (
            <>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            name="name"
                            label="Tên địa điểm"
                            rules={[requiredForm]}
                        >
                            <Input placeholder="Nhập tên địa điểm" />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name="provinceId"
                            label="Tỉnh/TP"
                            rules={[requiredForm]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%', height: 40 }}
                                placeholder={"Chọn tỉnh"}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(lstProvince || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    {
                        typeSelected == 1 ?
                            <Col span={2}>
                                <Form.Item
                                    name="rating"
                                    label="Số sao"
                                >
                                    <InputNumber min={0} style={{ height: 40, width: '100%' }} />
                                </Form.Item>
                            </Col> : null
                    }
                    {
                        typeSelected >= 3 ?
                            null :
                            <Col span={11}>
                                <Form.Item
                                    name="address"
                                    label="Địa chỉ"
                                >
                                    <Input placeholder="Nhập địa chỉ" />
                                </Form.Item>
                            </Col>
                    }

                </Row>

                <Row gutter={16}>
                    {
                        typeSelected >= 3 ?
                            <Col span={8}>
                                <Form.Item
                                    name="strong"
                                    label="Thế mạnh"
                                >
                                    <Input.TextArea placeholder="Thông tin thế mạnh" />
                                </Form.Item>
                            </Col> : null

                    }
                    <Col span={typeSelected == 1 ? 12 : 8}>
                        <Form.Item
                            name="contact"
                            label="Thông tin liên hệ"
                        >
                            <Input.TextArea placeholder="Thông tin liên hệ" />
                        </Form.Item>
                    </Col>
                    <Col span={typeSelected == 1 ? 12 : 8}>
                        <Form.Item
                            name="note"
                            label="Ghi chú"
                        >
                            <Input.TextArea placeholder="Ghi chú" />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    typeSelected == 2 ?
                        <>
                            <Button icon={<PlusOutlined />} type="primary" onClick={handleAddMenu}>Thêm menu</Button>
                            <Row gutter={24} style={{ backgroundColor: '#fafafa', padding: 16, borderRadius: 8, marginLeft: 0, marginRight: 0, marginTop: 8 }}>
                                {
                                    lstMenu.length > 0 ? lstMenu.map((item, index) => (
                                        <ItemMenu
                                            index={index}
                                            data={item}
                                            onDelete={handleDeleteMenu}
                                            onEdit={handleEditMenu}
                                            key={index.toString()}
                                            onCopy={handleCopyMenu}
                                        />
                                    )) : null
                                }
                            </Row>
                        </>

                        : null
                }
                <p style={{ fontWeight: 'bold', marginBottom: 12 }}>Hình ảnh đính kèm</p>
                <Row>
                    <>
                        <Upload {...props}
                            listType="picture-card"
                            fileList={fileList}
                            maxCount={3}
                            accept='image/png,image/jpg,image/jpeg,.pdf'
                            onPreview={handlePreview}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                        >
                            {fileList.length >= 3 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                wrapperStyle={{
                                    display: 'none',
                                }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </>
                </Row>
            </>
        )
    }

    useEffect(() => {
        if (type === TYPE_MODAL.ADD) {
            form.resetFields();
            setTypeSelected();
            setFileList([]);
            setLstMenu([]);
            setIsDisable(false);
        } else if (type === TYPE_MODAL.EDIT) {
            setTypeSelected(data?.typeId);
            setIsDisable(true);
            setLstMenu(data?.menus ? [...data?.menus] : []);
            setFileList(data?.attachments ? [...data?.attachments.map((file, index) => ({
                uid: file.id, // UID là duy nhất cho mỗi file
                name: file.file_path, // Tên file (có thể tùy chỉnh theo tên từ server)
                status: 'done', // Đánh dấu file đã được upload
                url: BASE_URL + file.file_path, // Đường dẫn file
            }))] : []);
            form.setFieldsValue({
                typeId: data?.typeId,
                name: data?.name ?? '',
                provinceId: data?.provinceId,
                rating: data?.rating ? data?.rating : null,
                address: data?.address ?? '',
                strong: data?.strong ?? '',
                contact: data?.contact ?? '',
                note: data?.note ?? ''
            });

        }

    }, [type, data, visible])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={'80%'}
        >
            <Form
                form={form}
                name="addProduct"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="typeId"
                    label="Loại hình kinh doanh"
                    rules={[requiredForm]}
                >
                    <Select
                        showSearch
                        disabled={isDisable}
                        style={{ width: '40%', height: 40 }}
                        placeholder={"Chọn loại hình"}
                        defaultActiveFirstOption={false}
                        filterOption={filterOption}
                        notFoundContent={null}
                        options={(lstType || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                        }))}
                        onChange={handleGetTypeService}
                    />
                </Form.Item>
                {renderModal()}
                <Row justify={"end"}>
                    <Space>
                        <Button type="default" onClick={onCancel}>
                            Thoát
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>

            </Form>
            <ModalAddMenu
                title={typeModalMenu === TYPE_MODAL.ADD ? "Thêm mới" : "Chỉnh sửa"}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                onSave={handleOnSaveMenu}
                data={menuSelected}
                type={typeModalMenu}
                indexEdit={indexEdit}
            />
        </Modal>
    )
}
export default React.memo(AddDataModal);
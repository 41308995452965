import { CheckOutlined, DeleteOutlined, PlusOutlined, RightCircleOutlined } from "@ant-design/icons"
import { Card, Col, Popconfirm, Space } from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import { formatNumber } from "../../../utils/funcHelper"

const ItemMenu = ({ index, data, onDelete, onEdit, onCopy }) => {
    
    return (
        <Col span={8}>
            <Card
                type="inner"
                title={`${data?.name} - ${data?.price ? formatNumber(data?.price) : ''}`}
                bordered={false}
                style={{
                    marginTop: 8,
                    marginBottom: 8
                }}
                headStyle={{
                    backgroundColor: '#AFD198',
                    color: "#000",
                    height: 30,
                    fontWeight: '600',
                    fontSize: 18
                }}
                extra={
                    <Space>
                        <a style={{ color: 'blue', textDecorationLine: 'underline', paddingRight: 5 }} onClick={() => onEdit(index)} href="#">Sửa</a>
                        <Popconfirm
                            title="Xoá menu"
                            description="Bạn có muốn menu này không?"
                            onConfirm={() => onDelete(index)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <a style={{ color: 'red', textDecorationLine: 'underline' }} href="#">Xoá</a>
                        </Popconfirm>
                        <a style={{ color: 'black', textDecorationLine: 'underline', paddingRight: 5 }} onClick={() => onCopy(index)} href="#">Copy</a>
                    </Space>}
            >
                <div dangerouslySetInnerHTML={{ __html: data?.content?.replace(/\n/g, '<br/>') }} />
            </Card>
        </Col>
    )
}
export default React.memo(ItemMenu)
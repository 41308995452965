import { Images } from "../../../assets"

const ItemPage = () => {

    return (
        <div>
            {/* header page */}
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <img src={Images.ic_logo_travel} style={{width: 80, height: 80}}/>
                <p style={{color: 'red'}}>SÔNG HÀN RIVER 4 SAO <span style={{color: 'black'}}> 14 Lý Tự Trọng, Thạch Thang, Hải Châu, Đà Nẵng</span></p>
            </div>
            <div style={{ position: 'relative' }}>
                <img src={Images.ic_header} style={{ height: 40, width: '50%' }} />
                <div style={{ position: 'absolute', top: 5, left: 0 }}>
                    <span style={{ fontSize: 20, fontWeight: '700', fontFamily: 'initial', color: 'yellow', marginLeft: 60 }}>KHÁCH SẠN LƯU TRÚ</span>
                </div>
            </div>
        </div>
    )
}
export default ItemPage;
import { useRef } from "react";
import { Images } from "../../assets";
import ItemPage from "./component/ItemPage";
import ReactToPrint from "react-to-print";
import { Button } from "antd";

const CustomSlidePage = () => {
    const printRef = useRef();
    return (
        <div style={{background: 'white'}} ref={printRef}>
            {/* anh banner */}
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{background: 'white', flex: 1, alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                    <img src={Images.ic_logo_travel} style={{width: 80, height: 80}}/>
                    <p style={{color: "#2e7498", fontSize: 45, fontFamily: "sans-serif", fontWeight: '600'}}>CHI TIẾT</p>
                    <p style={{color: "#2e7498", fontSize: 70, fontFamily: "initial", fontWeight: 'bold'}}>DỊCH VỤ</p>
                    <p style={{color: "#004aad", fontSize: 40, fontFamily: "initial", fontWeight: 'bold'}}>ĐÀ NẴNG</p>
                    <p style={{color: "#fff", fontSize: 16, fontFamily: "initial", fontWeight: 'bold', backgroundColor: "#004aad", paddingTop: 6, paddingBottom: 6, paddingLeft: 20, paddingRight: 20, borderRadius: 20}}>CHƯƠNG TRÌNH 3 NGÀY 2 ĐÊM</p>
                </div>
                <div style={{display: 'flex', flex: 1, width: '50%', backgroundColor: 'yellow'}}>
                    <img 
                        src="https://img.freepik.com/free-photo/bikini-girl-holding-man-s-hand-leading-him-ocean-krabi-thailand_335224-1294.jpg"
                        style={{width: '100%'}}
                    />
                </div>
            </div>
            {/* thong tin khach san */}
            <div>

            </div>
            <ItemPage />
            <ReactToPrint
                trigger={() => <Button type="primary">In slide</Button>}
                content={() => printRef.current}
                pageStyle={`@page { size: A4 landscape; }`}
            />
        </div>
    )
}

export default CustomSlidePage;
import {
    LogoutOutlined,
    MenuFoldOutlined,
    FileSearchOutlined,
    ExceptionOutlined,
    TeamOutlined,
    BarChartOutlined,
    TagOutlined,
    BookOutlined,
    ProjectOutlined,
    MenuOutlined,
    SendOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const LIST_MENU_SIDE_BAR = [
    
    {
        key: 'list',
        path: "/list",
        label: 'Danh sách điểm',
        icon: <SendOutlined />,
        permission: [1, 2, 3, 4]
    },
    // {
    //     key: 'add',
    //     path: "/add",
    //     label: 'Thêm mới',
    //     icon: <SendOutlined />,
    //     permission: [1, 2, 3, 4]
    // },
    
    {
        label: 'Danh mục',
        icon: <MenuFoldOutlined />,
        children: [
            {
                key: 'type',
                path: "/type",
                label: 'Loại hình kinh doanh',
                icon: <FileSearchOutlined />,
                permission: [1]
            },
            {
                key: 'province',
                path: "/province",
                label: 'Danh mục tỉnh',
                icon: <ExceptionOutlined />,
                permission: [1]
            }
        ]
    },
    {
        key: 'slide',
        path: "/slide",
        label: 'Slide',
        icon: <TeamOutlined />,
        permission: [1]
    },
    // {
    //     key: 'quan-ly-tai-khoan',
    //     path: "/quan-ly-tai-khoan",
    //     label: 'Quản lý email',
    //     icon: <TeamOutlined />,
    //     permission: [1]
    // },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2, 3, 4]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];

export const SEX_VALUE = [
    {
        label: "Nam",
        value: "1", 
    },
    {
        label: "Nữ",
        value: "0", 
    }
];

export const TYPE_USER = [
    {
        label: "Quản trị viên",
        value: "0", 
    },
    {
        label: "Học sinh",
        value: "1", 
    },
    {
        label: "Chuyên gia",
        value: "2", 
    }
];
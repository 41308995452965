import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Input, message, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import * as XLSX from 'xlsx';
import { addListEmailReceiver, getListAccount, saveLogActionSend } from "../../services/apis";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { useSelector } from "react-redux";


const AddNewProductPage = () => {
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    
    const userReducer = useSelector(state => state?.userReducer);

    
    useEffect(() => {
        
    }, [])
    return (
        <div>
            
        </div>
    );
}

export default AddNewProductPage;

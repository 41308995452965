import axiosClient from "./axios.client";

export const login = (params) => {
    return axiosClient.post('authen/login', params);
}

export const saveLogUser = (params) => {
    return axiosClient.post('logUser',params);
}
export const getListTypeService = () => {
    return axiosClient.get(`cms/category/getListTypeService`);
}

export const addNewTypeService = (params) => {
    return axiosClient.post('cms/category/addNewTypeService', params);
}

export const updateTypeServiceById = (params) => {
    return axiosClient.post('cms/category/updateTypeService', params);
}

export const getListProvince = (params) => {
    return axiosClient.get(`cms/category/getListProvince`);
}

export const getListProduct = (params) => {
    return axiosClient.post('cms/products/getListProducts', params);
}

export const addNewProduct = (params) => {
    return axiosClient.post('cms/products/addNewProduct', params);
}

export const getProductDetail = (params) => {
    return axiosClient.get(`cms/products/getProductDetail?productId=${params.id}`);
}

export const updateProduct = (params) => {
    return axiosClient.post('cms/products/updateProduct', params);
}

export const deleteProduct = (params) => {
    return axiosClient.post('cms/products/deleteProduct', params);
}

export const getListAttach = (params) => {
    return axiosClient.get(`cms/products/getListAttachFile?productId=${params?.productId}`);
}
import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import LoginPage from './screens/login/LoginPage';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';
import ListAccountPage from './screens/mana-account/ListAccountPage';
import AddNewProductPage from './screens/product/AddNewProductPage';
import ListTypeServicePage from './screens/category/type-service/ListTypeServicePage';
import ListProvincePage from './screens/category/province/ListProvincePage';
import ListProductPage from './screens/product/ListProductPage';
import CustomSlidePage from './screens/slide/CustomSlidePage';


const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route path="/login" element={
                    <LoginPage />
                } />
                <Route path="/" element={
                    <PrivateRoute>
                        <ListProductPage />
                    </PrivateRoute>
                } />
                <Route path="/list" element={
                    <PrivateRoute>
                        <ListProductPage />
                    </PrivateRoute>
                } />
                <Route path="/add" element={
                    <PrivateRoute>
                        <AddNewProductPage />
                    </PrivateRoute>
                } />
                <Route path="/type" element={
                    <PrivateRoute>
                        <ListTypeServicePage />
                    </PrivateRoute>
                } />
                <Route path="/province" element={
                    <PrivateRoute>
                        <ListProvincePage />
                    </PrivateRoute>
                } />
                <Route path="/slide" element={
                    <PrivateRoute>
                        <CustomSlidePage />
                    </PrivateRoute>
                } />
                {/* <Route path="/dashboard" element={
                    <PrivateRoute>
                        <DashboardPage />
                    </PrivateRoute>
                } /> */}
                
                <Route path="/quan-ly-tai-khoan" element={
                    <PrivateRoute>
                        <ListAccountPage />
                    </PrivateRoute>
                } />
                
            </Routes>

        </BrowserRouter>
    );
};

export default AppRouter;
import { Button, Input, Modal, Form, Row, Col, Space, InputNumber } from "antd"
import React, { useEffect, useState } from "react";
import { TYPE_MODAL } from "../../../constants/constants";
import { requiredForm } from "../../../utils/requiredform";



const ModalAddMenu = ({ visible, onCancel, onSave, title, type, data, indexEdit }) => {
    const [form] = Form.useForm();

    const onFinish = (dataF) => {
        if (type === TYPE_MODAL.ADD) {
            onSave(dataF);
        } else if (type === TYPE_MODAL.EDIT) {
            const newData = {
                ...data,
                name: dataF?.name,
                content: dataF?.content,
                price: dataF?.price ?? 0
            }
            onSave(newData, indexEdit);
        }
    }

    useEffect(() => {
        if (type === TYPE_MODAL.ADD) {
            form.resetFields();
        } else if (type === TYPE_MODAL.EDIT) {
            form.setFieldsValue({
                name: data?.name,
                price: data?.price ??  0,
                content: data?.content
            })
        }
    }, [type, data, visible])
    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={'50%'}
        >
            <Form
                form={form}
                name="addMenu"
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Tên menu"
                            rules={[requiredForm]}
                        >
                            <Input placeholder="Nhập tên" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="price"
                            label="Giá"
                        >
                            <InputNumber 
                                placeholder={'0'}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                className="input-number-base"
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label="Nội dung thực đơn"
                            rules={[requiredForm]}
                        >
                            <Input.TextArea placeholder="Nhập Nội dung" size="large" style={{ height: 150 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={"end"}>
                    <Space>
                        <Button type="default" onClick={onCancel}>
                            Thoát
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>

            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddMenu);
import { Form, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../../components/LoadingBase';
import '../../../css/listbook-page.css';
import { getListProvince } from '../../../services/apis';

const ListProvincePage = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            align: 'center',
        },
        {
            title: 'Tên tỉnh',
            dataIndex: 'name',
            key: 'id',
            render: (text) => <div style={{padding: 8}}>{text}</div>
        },
        
    ];
    
    const getDataProvince = async (currentPage) => {
        const params = {
            keyword: form.getFieldValue("keyword") ?? '',
            limit: 50,
            currentPage: currentPage
        }
        setLoading(true);
        const res = await getListProvince(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data]);
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    

    useEffect(() => {
        getDataProvince(1);
    }, [])

    return (
        <div className='container-content'>
            
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListProvincePage);
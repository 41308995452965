import React, { useEffect, useState } from "react";
import '../../css/login-page.css';
import { Images } from "../../assets";
import { login, saveLogUser } from "../../services/apis";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import LoadingBase from "../../components/LoadingBase";
import { useDispatch, useSelector } from "react-redux";
import { saveUserInfo } from "../../redux/action";
import jwtDecode from "jwt-decode";
import moment from "moment";
const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const userReducer = useSelector(state => state?.userReducer);

    const handleOnchangePass = (event) => {
        setPassword(event.target.value);
    }

    const handleOnchangeUsername = (event) => {
        setUserName(event.target.value);
    }
    const saveLog = async (action, username) => {
        const params = {
            action: action,
            username: username,
            description: 'Click đăng nhập'
        };
        const res = await saveLogUser(params);
    }
    const handleLogin = async () => {

        if (!userName || !password) {
            message.error("Vui lòng không để trống tài khoản hoặc mật khẩu");
            return;
        }
        try {
            const params = {
                username: userName,
                password: password
            }
            setLoading(true);
            const res = await login(params);
            setLoading(false);
            console.log("res login", res);
            if (res && res?.status === 1) {
                message.success("Đăng nhập thành công");
                saveLog("Đăng nhập thành công", userName);
                dispatch(saveUserInfo(res?.data));
                navigate("/");
            } else {
                message.error("Đăng nhập thất bại");
                saveLog("Đăng nhập thất bại", userName);
            }
        } catch (error) {
            setLoading(false);
            message.error("Đăng nhập thất bại");
            saveLog("Đăng nhập lỗi", userName);
        }
    }
    useEffect(() => {
        if (userReducer && userReducer?.data && userReducer?.data?.access_token) {
            const decodedToken = jwtDecode(userReducer?.data?.access_token);
            if (decodedToken && decodedToken?.exp) {
                if (moment(decodedToken?.exp * 1000).isAfter(moment())) {
                    navigate("/");
                }
            }
        }
    }, [userReducer])
    return (
        <div className="login-body">
            <div className="signin">
                <div className="content">
                    <img src={Images.img_logo_app} className="img-logo" />
                    <h3>Đăng nhập hệ thống</h3>
                    <div className="form">

                        <div className="inputBox">

                            <input placeholder="Tài khoản" type="text" required onChange={handleOnchangeUsername} />

                        </div>

                        <div className="inputBox">

                            <input placeholder="Mật khẩu" type="password" required onChange={handleOnchangePass} />

                        </div>

                        <div className="links">
                            {/* <input type="checkbox"/>
                                <label className="remember">Lưu mật khẩu</label> */}
                        </div>

                        <div className="inputBox">

                            <input type="submit" value="Đăng nhập" onClick={handleLogin} />

                        </div>

                    </div>

                </div>

            </div>
            <LoadingBase loading={loading} />
        </div>



    );
}


export default LoginPage;
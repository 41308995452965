import { Button, Col, Form, Input, Row, Space, theme, Table, message, Select, Popconfirm, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { addNewProduct, deleteProduct, getListAttach, getListProduct, getListProvince, getListTypeService, getProductDetail, updateProduct } from '../../services/apis';
import { TYPE_MODAL } from '../../constants/constants';
import { useSelector } from 'react-redux';
import FooterTable from '../../components/FooterTable';
import AddDataModal from './component/AddDataModal';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import { filterOption } from '../../utils/funcHelper';
import { BASE_URL } from '../../settings/config';

const ListProductPage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [totalRecord, setTotalRecord] = useState(0);
    const [titleModal, setTitleModal] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [dataType, setDataType] = useState([]);
    const [dataProvince, setDataProvince] = useState([]);
    const [loadingRow, setLoadingRow] = useState({});
    const [contentRow, setContentRow] = useState({});
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Tên địa điểm',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 180,
            render: (text, record) => 
                <Popover content={() => loadingRow[record.id] ? <Spin /> : showLinkRow(contentRow[record.id]) || <Spin />} 
                title="Thông tin đính kèm" 
                trigger="click"
                onVisibleChange={(visible) => {
                    if (visible && !contentRow[record.id]) {
                      showInfoLinkAttach(record.id);
                    }
                  }}
                >
                    <a style={{ color: 'blue', paddingLeft: 12 }} href="#">{text}</a>
                </Popover>
        },
        {
            title: 'Tỉnh/TP',
            dataIndex: 'provinceName',
            key: 'provinceName',
            align: 'center',
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Loại hình',
            dataIndex: 'typeName',
            key: 'typeName',
            align: 'center',
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Sao',
            dataIndex: 'rating',
            key: 'rating',
            align: 'center',
            width: 50,
            render: (text) => <div>{text}</div>
        },
        {
            title: 'Điểm mạnh',
            dataIndex: 'strong',
            key: 'strong',
            ellipsis: true,
            render: (text) => 
                <Popover content={() => formattedContent(text)} title="Điểm mạnh" trigger="click">
                    <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
                </Popover>
        },
        {
            title: 'Liên hệ',
            dataIndex: 'contact',
            key: 'contact',
            ellipsis: true,
            render: (text) =>
                <Popover content={() => formattedContent(text)} title="Liên hệ" trigger="click">
                    <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
                </Popover>

        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            render: (text) => 
                <Popover content={() => formattedContent(text)} title="Địa chỉ" trigger="click">
                    <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
                </Popover>
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            ellipsis: true,
            render: (text) =>
                <Popover content={() => formattedContent(text)} title="Ghi chú" trigger="click">
                    <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
                </Popover>
        },
        {
            title: 'Hành động',
            key: 'action',
            align: 'center',
            render: (text, record) => <Space size={20}>
                <a onClick={() => handleEdit(record)} style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>Sửa</a>

                <Popconfirm
                    title="Xoá menu"
                    description="Bạn có muốn menu này không?"
                    onConfirm={() => onDelete(record)}
                    okText="Có"
                    cancelText="Không"
                >
                    <a style={{ color: 'red', textDecorationLine: 'underline' }} href="#">Xoá</a>
                </Popconfirm>
            </Space>
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const showLinkRow = (data) => {
        if (!data || data?.length === 0) {
            return (
                <React.Fragment>
                  {"Không có dữ liệu"}
                  <br />
                </React.Fragment>
              );
        } else if (data?.length > 0){
            return data.map((item, index) => (
                <React.Fragment key={index}>
                  <a href={BASE_URL + item.file_path} target='_blank'>{item.file_path}</a>
                  <br />
                </React.Fragment>
              ))
        }
    }

    const showInfoLinkAttach = async (id) => {
        // Kiểm tra xem dữ liệu đã được tải chưa
    if (contentRow[id]) {
        return contentRow[id];
      }
  
      // Bắt đầu tải dữ liệu
      setLoadingRow(prev => ({ ...prev, [id]: true }));
  
      const params = {
        productId: id
      }
      const res = await getListAttach(params);
      consoleLogUtil("getListAttach",res)
      setLoadingRow(prev => ({ ...prev, [id]: false }));
      if (res && res.status === 1) {
        setContentRow(prev => ({ ...prev, [id]: res?.data}));
      } else {
        setContentRow(prev => ({ ...prev, [id]: []}));
      }
    }

    const formattedContent = (content) => content.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));

    const getDataType = async () => {
        const res = await getListTypeService();
        if (res && res.status === 1) {
            setDataType([...res?.data]);
        } else {
            setDataType([]);
        }
    }
    const onDelete = async (item) => {
        try {
            const params = {
                id: item?.id
            }
            setLoading(true);
            const res = await deleteProduct(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success("Xoá địa điểm thành công");
                getDataProduct(1);
            } else {
                message.warning(res?.msg ?? "Có lỗi xảy ra vui lòng thử lại")
            }
        } catch (error) {
            setLoading(false);
            message.warning("Có lỗi xảy ra");
        }


    }
    const getDataProvince = async () => {
        const params = {
            keyword: '',
            limit: 100,
            currentPage: 1
        }
        const res = await getListProvince(params);
        if (res && res.status === 1) {
            setDataProvince([...res?.data]);
        } else {
            setDataProvince([]);
        }
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataProduct(page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataProduct(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataProduct(value)
    }

    const handleEdit = async (item) => {
        setTypeModal(TYPE_MODAL.EDIT);
        const params = {
            id: item.id
        }
        setLoading(true);
        const res = await getProductDetail(params);
        setLoading(false);
        consoleLogUtil("getProductDetail", res);
        if (res && res.status === 1) {
            setShowModal(true);
            setTitleModal("Sửa thông tin địa điểm");
            setItemSelected(res?.data);
        } else {
            setShowModal(false);
            message.warning(res?.msg ?? "Không tải được dữ liệu hoặc thông tin không còn tồn tại")
        }

    }
    const getDataProduct = async (page, isShowLoading) => {
        if (!isShowLoading) {
            setLoading(true);
        }
        const params = {
            keyword: form.getFieldValue('name') ?? '',
            provinceId: form.getFieldValue('provinceId') ?? null,
            typeId: form.getFieldValue('typeId') ?? null,
            limit: 50,
            currentPage: page
        }
        const res = await getListProduct(params);
        if (!isShowLoading) {
            setLoading(false);
        }
        if (res && res.status === 1) {
            setData([...res?.data?.data]);
            setTotalPage(res?.data?.totalPages);
            setTotalRecord(res?.data?.totalRows);
        } else {
            setData([]);
            setTotalPage(0);
            setTotalRecord(0);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const onFinish = async (data) => {
        getDataProduct(1);
    };
    const handleCancel = () => {
        setShowModal(false);
    }
    const addProduct = async (data) => {
        try {
            setLoading(true);
            const params = {
                ...data,
                createById: userReducer?.data?.user_info?.id
            };
            const res = await addNewProduct(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Thêm mới dữ liệu thành công");
                getDataProduct(1);
            }
        } catch (error) {
            setLoading(false);
            consoleLogUtil("addNewProduct error", error)
        }
    }
    const updateDataProduct = async (data) => {
        try {
            setLoading(true);
            const params = {
                ...data,
                createById: userReducer?.data?.user_info?.id
            };
            const res = await updateProduct(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Chỉnh sửa dữ liệu thành công");
                getDataProduct(1);
            }
        } catch (error) {
            setLoading(false);
            consoleLogUtil("addNewProduct error", error)
        }
    }
    const handleAddAndEditData = async (data) => {
        setShowModal(false);
        consoleLogUtil("handleAddData", data);
        if (typeModal === TYPE_MODAL.ADD) {
            addProduct(data);
        } else if (typeModal === TYPE_MODAL.EDIT) {
            updateDataProduct(data);
        }

    }
    useEffect(() => {
        setLoading(true);
        Promise.all([getDataProduct(1, true), getDataProvince(), getDataType()]).finally(() => setLoading(false));
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên loại'} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name="provinceId"
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{ width: '100%', height: 40 }}
                                placeholder={"Chọn tỉnh"}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(dataProvince || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name="typeId"
                        >
                            <Select
                                showSearch
                                allowClear
                                style={{ width: '100%', height: 40 }}
                                placeholder={"Chọn loại hình"}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={(dataType || []).map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setTitleModal("Thêm mới");
                                    setTypeModal(TYPE_MODAL.ADD)
                                }}
                            >
                                Thêm mới
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
                scroll={{ x: 1300 }}
            />
            <FooterTable
                totalRecord={totalRecord}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <AddDataModal
                visible={showModal}
                onCancel={handleCancel}
                onSave={handleAddAndEditData}
                title={titleModal}
                lstProvince={dataProvince}
                lstType={dataType}
                type={typeModal}
                data={itemSelected}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListProductPage);
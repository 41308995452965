import { Button, Col, Form, Input, Row, Space, theme, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../../components/LoadingBase';
import '../../../css/listbook-page.css';
import AddDataModal from './component/AddDataModal';
import { addNewTypeService, getListTypeService, updateTypeServiceById } from '../../../services/apis';
import { TYPE_MODAL } from '../../../constants/constants';
import { useSelector } from 'react-redux';

const ListTypePage = () => {
    const { token } = theme.useToken();
    const userReducer = useSelector(state => state?.userReducer);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [titleModal, setTitleModal] = useState('');
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const columns = [
        {
            title: 'Mã',
            dataIndex: 'id',
            key: 'id',
            width: 70,
            align: 'center',
        },
        {
            title: 'Tên loại',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>{text}</div>
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => <a onClick={() => handleEdit(record)} style={{paddingTop: 8, paddingBottom: 8, paddingLeft: 12}}>Sửa</a>
        },
    ];
    
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleEdit = (item) => {
        setTypeModal(TYPE_MODAL.EDIT);
        setShowModal(true);
        setTitleModal("Sửa thông tin địa điểm");
        setItemSelected(item);
    }
    const getDataType = async () => {
        setLoading(true);
        const res = await getListTypeService();
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data]);
        } else {
            setData([]);
            message.error(res?.msg ?? "Lấy dữ liệu thất bại");
        }
    }

    const handleAddOrEditData = async (data) => {
        setShowModal(false);
        setLoading(true);
        if (typeModal === TYPE_MODAL.ADD) {
            const params = {
                name: data?.name ?? ''
            };
            
            const res = await addNewTypeService(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Thêm mới loại thành công");
                getDataType("");
            } else {
                message.error(res?.msg ?? "Thêm mới thất bại!")
            }
        } else {
            const params = {
                id: data?.id,
                name: data?.name ?? '',
            };
            
            const res = await updateTypeServiceById(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success("Chỉnh sửa thành công");
                getDataType("");
            } else {
                message.error(res?.msg ?? "Chỉnh sửa thất bại!")
            }
        }
    }
    const handleCancel = () => {
        setShowModal(false);
    }

    const onFinish = async (data) => {
        getDataType(data?.name);
    };

    useEffect(() => {
        getDataType();
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Tên loại'} />
                        </Form.Item>
                    </Col>
                    
                    
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>
                            {/* <Button
                                onClick={() => {
                                    setShowModal(true);
                                    setTitleModal("Thêm mới loại");
                                    setTypeModal(TYPE_MODAL.ADD)
                                }}
                            >
                                Thêm mới
                            </Button> */}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: 50
                }}
                rowKey={(record) => record?.id}
                bordered
                rowClassName={() => 'custom-row'}
            />
            <AddDataModal visible={showModal} onCancel={handleCancel} onSave={handleAddOrEditData} title={titleModal} type={typeModal} data={itemSelected}/>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListTypePage);
import { Button, Input, Modal } from "antd"
import React, { useEffect, useState } from "react";
import { TYPE_MODAL } from "../../../../constants/constants";

const AddDataModal = ({ visible, onCancel, onSave, title, type, data }) => {
    const [name, setName] = useState('');
    const [department, setDepartment] = useState(data);
    const handleSave = () => {
        if (type === TYPE_MODAL.ADD) {
            const newData = {
                name
            }
            if (name?.length > 0) {
                onSave(newData);
            }
        } else {
            if (name?.length > 0) {
                const temp = {
                    ...department,
                    name
                };
                onSave(temp);
            }
        }
        setName('');
    };

    useEffect(() => {
        if (type === TYPE_MODAL.ADD) {
            setName('');
        } else {
            setDepartment(data);
            setName(data?.name ?? '');
        }
    }, [data, type])

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Lưu
                </Button>,
            ]}
        >
            <p>Tên loại hình</p>
            <Input
                placeholder="Nhập tên loại hình"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            
        </Modal>
    )
}
export default React.memo(AddDataModal);